import api from './api'
let video_url = process.env.VUE_APP_BASEURL

export default {
  videourl : video_url,
  iwitnessdatafind(data){
    return api.execute(`get`,`/iwitness/data/`+data)
  },
  iwitnessvideofind(data){
    return api.execute(`get`,`/iwitness/video/`+data)
  },
  eventlist(data){
    return api.execute(`get`,`/iwitness/eventlist/`+data)
  },
  addFav(data,payload){
    return api.execute(`post`,`/iwitness/fav/`+data,payload)
  },
  getFav(){
    return api.execute(`get`,`/iwitness/fav/`)
  },
  deleteFav(id){
    return api.execute(`DELETE`,`/iwitness/fav/`+id)
  },
}
